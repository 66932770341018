<template>
  <div id="ejcon" class="ejcon"  ref="appRef">
    <div class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
<!-- 头部 -->
         <div class="top-header">
            <dv-decoration-8 class="header-left-decoration" />
            <dv-decoration-5 class="header-center-decoration" dur="3" />
            <dv-decoration-8 class="header-right-decoration" :reverse="true" />
            <div class="center-title">项目用时</div>
          </div>
<div class="return" @click="returnBack">
            <img src="@/assets/return.png" alt="">
          </div>

  <!-- 年级 -->
  <div class="classInfo">
      <dv-border-box-6 :color="['#155e9f', '#0b6ec7']">
        
        <div class="classInfo_Con">
 <dv-scroll-board class="dv-scr-board" :config="config" />
         
          
        </div>
      </dv-border-box-6>
    
  </div>
       
      </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../utils/drawMixin";


export default {
  mixins: [ drawMixin ],
  data() {
    return {
      timing: null,
      loading: true,
      digitalFlopData: [],
      config: {
        header: ['<span style="color:#9abeff;font-weight:bold">项目</span>', 
        '<span style="color:#9abeff;font-weight:bold;">班级</span>', 
        '<span style="color:#9abeff;font-weight:bold;">平均成绩</span>', 
        '<span style="color:#9abeff;font-weight:bold">男生总用时</span>',
        '<span style="color:#9abeff;font-weight:bold">女生总用时</span>'],
        data: [
          ['跳远','一年级（1）班', "<span style='color:#ffe110'>1.8米</span>", "25min","20min"],
          ['跳远','一年级（2）班', "<span style='color:#ffe110'>1.67米</span>", "25min","18min"],
          ['跳远','一年级（3）班', "<span style='color:#ffe110'>1.72米</span>", "27min","21min"],
          ['跳远','一年级（4）班', "<span style='color:#ffe110'>1.7米</span>", "17min","21min"],
          ['跳远','一年级（5）班', "<span style='color:#ffe110'>1.69米</span>", "20min","21min"],
          ['50米跑','一年级（1）班', "<span style='color:#ffe110'>12s</span>", "25min","21min"],
          ['50米跑','一年级（2）班', "<span style='color:#ffe110'>11.36s</span>", "25min","19min"],
          ['50米跑','一年级（3）班', "<span style='color:#ffe110'>13s</span>", "25min","21min"],
          ['50米跑','一年级（4）班', "<span style='color:#ffe110'>11.4s</span>", "25min","20min"],
          ['50米跑','一年级（5）班', "<span style='color:#ffe110'>13.5s</span>", "27min","21min"],
           ['仰卧起坐','一年级（1）班', "<span style='color:#ffe110'>45个</span>", "25min","21min"],
          ['仰卧起坐','一年级（2）班', "<span style='color:#ffe110'>48个</span>", "27min","21min"],
          ['仰卧起坐','一年级（3）班', "<span style='color:#ffe110'>49个</span>", "25min","21min"],
          ['仰卧起坐','一年级（4）班', "<span style='color:#ffe110'>42个</span>", "20min","27min"],
          ['仰卧起坐','一年级（5）班', "<span style='color:#ffe110'>41个</span>", "25min","28min"],
           ['一分钟跳绳','一年级（1）班', "<span style='color:#ffe110'>132个</span>", "27min","21min"],
          ['一分钟跳绳','一年级（2）班', "<span style='color:#ffe110'>128个</span>", "25min","23min"],
          ['一分钟跳绳','一年级（3）班', "<span style='color:#ffe110'>116个</span>", "23min","21min"],
          ['一分钟跳绳','一年级（4）班', "<span style='color:#ffe110'>128个</span>", "28min","21min"],
          ['一分钟跳绳','一年级（5）班', "<span style='color:#ffe110'>115个</span>", "23min","27min"],
        ],
         rowNum: 9, //表格行数
        headerHeight: 80,
        headerBGC: '#072652', //表头
        oddRowBGC:'#06153c',
        evenRowBGC:'#06153c',
        align: ['left','left'],
        // columnWidth: [230,210,100,160,160,240,360],
      }
    }
  },
  components: {
   
  },
  mounted() {
    this.cancelLoading()
    
  },
  beforeDestroy () {
    clearInterval(this.timing)
  },
  methods: {
  //  加载动画
    cancelLoading() {
      setTimeout(() => {
        this.loading = false
      }, 500)
    },

   
    randomExtend (minNum, maxNum) {
      if (arguments.length === 1) {
        return parseInt(Math.random() * minNum + 1, 10)
      } else {
        return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
      }
    },
     returnBack(){
      this.$router.go(-1)
    },

  }
}
</script>

<style lang="scss" scoped>
.title{
  padding: 30px 24px;
    font-size: 22px;
}
.bg{
  padding: 0;
}
.ejcon {
  color: #d3d6dd;
  width: 1920px;
  height: 1080px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: left top;
  overflow: hidden;

  .host-body {
.top-header {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
  padding: 16px;
  margin-bottom: 16px;

  .header-center-decoration {
    width: 46%;
    height: 60px;
    margin-top: 20px;
  }

  .header-left-decoration, .header-right-decoration {
    width: 25%;
    height: 60px;
  }

  .center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 28px;
    transform: translateX(-50%);
    letter-spacing:8px;
    color: #0ebe9b;
  }
}
.return{
  position: fixed;
  z-index: 9999;
  cursor: pointer;
  left: 20px;
  top: 92px;
  width: 80px;
  height: 80px;
  background: rgba(0, 250, 255, 0.7);
  border-radius: 50%;
  border: 1px solid #0f319b;
  img{
    display: block;
    text-align: center;
    width: 36px;
    margin: 24px auto;
  }
}
  }

  
.classInfo{
  margin: 50px auto 0;
  width: 1400px;
  height: 840px;
  .classInfo_Con{
    width: 100%;

    margin: 0 auto;
      .dv-scr-board {
      //  margin: 30px 0;
       padding: 30px;
      //  width: 100%;
      height: 780px;
      color: #d4eaf6;
      ::v-deep .header-item{
        font-size: 24px !important;
      }
      ::v-deep .row-item{
        height: 78px !important;
        line-height:78px !important;
        font-size:20px !important;
        color: #9abeff !important;
        border-bottom: 1px solid #02365a !important;
      }
      
    }
    
  }
}

}
</style>
